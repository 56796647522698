import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import VendorList from '../components/Vendor/VendorList'; // Import VendorList for the vendor carousel

const HomePage = () => {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Bienvenidos a CompraSi</h1>
          <p>Descubre vendedores locales y apoya sus negocios.</p>
          <Link to="/become-vendor" className="signup-button">Hazte Vendedor</Link>
        </div>
      </section>

      {/* Vendor List Section */}
      <section className="vendor-list-section">
        <h2>Vendedores</h2>
        <VendorList isCarousel={true} /> {/* Render vendor list carousel */}
      </section>

      {/* Map Section */}
      <section className="map-section">
        <h2>Explorar Mapa</h2>
        <div className="map-preview">
          <div className="map-placeholder">🌍</div>
        </div>
        <Link to="/map" className="map-button">Ir al Mapa</Link>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 CompraSi. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default HomePage;
