import React, { useState } from 'react';
import '../styles/BecomeVendorScreen.css';

const countryCodes = [
  // Central America
  { code: '+502', country: 'Guatemala', emoji: '🇬🇹', phoneLength: 8 },
  { code: '+503', country: 'El Salvador', emoji: '🇸🇻', phoneLength: 8 },
  { code: '+504', country: 'Honduras', emoji: '🇭🇳', phoneLength: 8 },
  { code: '+505', country: 'Nicaragua', emoji: '🇳🇮', phoneLength: 8 },
  { code: '+506', country: 'Costa Rica', emoji: '🇨🇷', phoneLength: 8 },
  { code: '+507', country: 'Panamá', emoji: '🇵🇦', phoneLength: 8 },
  { code: '+52', country: 'México', emoji: '🇲🇽', phoneLength: 10 },
  // South America
  { code: '+54', country: 'Argentina', emoji: '🇦🇷', phoneLength: 10 },
  { code: '+55', country: 'Brasil', emoji: '🇧🇷', phoneLength: 11 },
  { code: '+56', country: 'Chile', emoji: '🇨🇱', phoneLength: 9 },
  { code: '+57', country: 'Colombia', emoji: '🇨🇴', phoneLength: 10 },
  { code: '+58', country: 'Venezuela', emoji: '🇻🇪', phoneLength: 10 },
  { code: '+591', country: 'Bolivia', emoji: '🇧🇴', phoneLength: 8 },
  { code: '+593', country: 'Ecuador', emoji: '🇪🇨', phoneLength: 9 },
  { code: '+595', country: 'Paraguay', emoji: '🇵🇾', phoneLength: 9 },
  { code: '+597', country: 'Surinam', emoji: '🇸🇷', phoneLength: 7 },
  { code: '+598', country: 'Uruguay', emoji: '🇺🇾', phoneLength: 9 },
  // North America
  { code: '+1', country: 'Estados Unidos', emoji: '🇺🇸', phoneLength: 10 },
];

const BecomeVendorScreen = () => {
  const [selectedCode, setSelectedCode] = useState('+502');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [storeName, setStoreName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCodeSelect = (code) => {
    setSelectedCode(code);
    setPhoneNumber('');
    setIsDropdownOpen(false);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const selectedCountry = countryCodes.find((country) => country.code === selectedCode);

    // Format phone number for better readability
    let formatted = value;
    if (selectedCountry.phoneLength === 10 && value.length > 6) {
      formatted = `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
    } else if (value.length > 4) {
      formatted = `${value.slice(0, 4)} ${value.slice(4)}`;
    }

    if (value.replace(/\s/g, '').length <= selectedCountry.phoneLength) {
      setPhoneNumber(formatted);
    }
  };

  const isFormValid = () => {
    const selectedCountry = countryCodes.find((country) => country.code === selectedCode);
    return (
      storeName.trim() !== '' &&
      description.trim() !== '' &&
      phoneNumber.replace(/\s/g, '').length === selectedCountry.phoneLength
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert('Por favor, complete todos los campos correctamente.');
      return;
    }

    setIsSubmitting(true);
    const whatsappNumber = `12087602524`;
    const message = `Hola, soy ${storeName}.\nDescripción: ${description}\nNúmero: ${selectedCode}${phoneNumber.replace(/\s/g, '')}`;
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappURL, '_blank');
    setIsSubmitting(false);
  };

  return (
    <div className="become-vendor-screen">
      <div className="form-container">
        <h1>Regístrate como Vendedor</h1>
        <form className="vendor-form" onSubmit={handleSubmit}>
          <label htmlFor="store-name">Nombre de la Tienda:</label>
          <input
            type="text"
            id="store-name"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            maxLength={50}
            placeholder="Ingrese el nombre de su tienda"
            required
          />

          <label htmlFor="description">Descripción de la Tienda:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={300}
            placeholder="Ingrese una descripción de su tienda"
            required
          ></textarea>

          <label htmlFor="whatsapp">Número de WhatsApp:</label>
          <div className="phone-input">
            <div className="country-code-dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                onClick={toggleDropdown}
              >
                {countryCodes.find((country) => country.code === selectedCode)?.emoji} {selectedCode}
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  {countryCodes.map((country) => (
                    <div
                      key={country.code}
                      className="dropdown-item"
                      onClick={() => handleCodeSelect(country.code)}
                    >
                      {country.emoji} {country.country} ({country.code})
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder={`Ingrese su número (${selectedCode})`}
              required
            />
          </div>

          <button
            type="submit"
            className={`submit-button ${!isFormValid() ? 'disabled' : ''}`}
            disabled={!isFormValid() || isSubmitting}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BecomeVendorScreen;
